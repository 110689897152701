
import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web"; 

function Card() {
  const [show, setShown] = useState(false);

  
  return (
    <div>
        
    </div>
  );
}

export default Card;
